import { Clock as ClockIcon } from "react-feather";
import React, { useState } from "react";
import { formatDateWithFormat } from "../../../../utility/format";

const Clock = () => {
  let time = formatDateWithFormat(new Date().toISOString(),"HH:mm A");

  const [ctime, setTime] = useState(time);
  const UpdateTime = () => {
    time = formatDateWithFormat(new Date().toISOString(),"hh:mm A");
    setTime(time);
  };
  setInterval(UpdateTime);
  return (
    <div className="d-flex justify-content-start align-items-center mr-1">
      <ClockIcon size={25} className="d-none d-md-inline mr-1" />
      {ctime} KSA
    </div>
  );
};

export default Clock;
